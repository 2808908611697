<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>商家流水</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 表头 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label v-text="$t('msg.login_time_a')">登录时间：</label>
                  <date-packer :datePacker="dateValue" @setDatePacker="getDatePacker"></date-packer>
               </el-row>
               <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
            <el-button type="primary"  plain @click="handleReset(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <div class="table-box">
            <el-table
                  :data="tableData"
                  style="width: 100%"
                  :stripe="true"
                  >
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="flowMoney" label="流水金额"></el-table-column>
               <el-table-column prop="incomeType" label="收入类型"></el-table-column>
               <el-table-column prop="createTime" label="创建时间"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
         </div>
      </el-row>
   </div>
</template>

<script>
   import { urlObj } from '@/api/interface'
   export default {
      data(){
         return{
            tableData: [],        // 表格数据
            page: 1,              // 当前页 默认第1页
            limit: 0,             // 每页显示数
            total: 0,             // 总数目数
            dateValue: [],        // 时间段
            beginCreateTime: '',  // 开始创建时间
            endCreateTime: '',    // 结束创建时间
         }
      },
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1
         let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
         if (userInfo.accountType === 'BUSINESS_SHOPPING') {
            this.companyId = userInfo.storeId
            this.getFlowBill()
         }
      },
      methods: {
         // 获取账单流水
         getFlowBill(){
            const url = urlObj.getFlow
            const param = {
               companyId: this.companyId,
               beginCreateTime: this.beginCreateTime,
               endCreateTime: this.endCreateTime,
               limit: this.limit,
               page: this.page
            }
            this.$axios.get(url, param).then(res => {
               if (res.success){
                  this.total = res.total
                  this.tableData = res.records
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取流水时间段
         getDatePacker(val){
            this.dateValue = val
         },
         // 搜索登录日志
         handleQuery(){
            if (this.dateValue) {
               this.beginCreateTime = this.dateValue[0].split(" ")[0]
               this.endCreateTime = this.dateValue[1].split(" ")[0]
            } else {
               this.beginCreateTime = ''
               this.endCreateTime = ''
            }
            this.getFlowBill()
         },
         // 重置搜索日志
         handleReset(){
            this.dateValue = [];
         },
         // 改变显示条数
         pageChange(v){
            this.limit = v
            this.getLogs()
         },
         // 改变当前页
         handlePaging(v){
            this.page = v
            this.getLogs()
         },
      }
   }
</script>

<style scoped></style>
